<template>
  <b-row class="mt-1">
    <!-- submit and reset -->
    <b-col v-if="disableForm" cols="12">
      <b-button variant="primary" class="mr-1" @click="edit"> Editar </b-button>
    </b-col>
    <b-col v-else cols="12">
      <b-button
        v-if="permissionEdit || permissionInsert"
        variant="primary"
        class="mr-1"
        @click="save"
        :disabled="loadingButton"
      >
        {{ loadingButton ? 'Aguarde...' : 'Salvar' }}
        <b-spinner v-if="loadingButton" small></b-spinner>
      </b-button>
      <b-button variant="outline-secondary" @click="cancel">
        Cancelar
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner
  },
  props: {
    disableForm: {
      type: Boolean,
      default: true
    },
    permissionEdit: {
      type: Boolean,
      default: true
    },
    permissionInsert: {
      type: Boolean,
      default: true
    },
    loadingButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    save() {
      this.$emit('save')
    },
    edit() {
      this.$emit('edit')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
